import { SupabaseClient } from '@supabase/supabase-js'

const HEIGHT_OF_SENSOR = 204
const TANK_RADIUS = 65

export type WaterLevelReading = {
    created_at: string
    reading: number
    dev: number
}

export const getCurrentWaterLevel = async (
    client: SupabaseClient,
    tank_id: number
): Promise<WaterLevelReading | undefined> => {
    const { data, error } = await client.rpc('waterlevel', {
        tank_id,
    })
    if (!error && data) {
        return {
            created_at: data.created_at,
            reading: data.average_reading,
            dev: data.max_dev,
        }
    } else {
        return undefined
    }
}

export const calculateWaterHeight = (sensorMeasurement: number) => {
    return HEIGHT_OF_SENSOR - sensorMeasurement
}

export const calcuateWaterVolume = (sensorMeasureMent: number) => {
    return Math.round(
        (3.14159 *
            (TANK_RADIUS * TANK_RADIUS) *
            calculateWaterHeight(sensorMeasureMent)) /
            1000
    )
}
